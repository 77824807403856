
import { defineComponent, onMounted, computed } from "vue";
import { useStore } from "vuex";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";
import { useRouter } from "vue-router";
import GoBackButton from "@/components/reusable/GoBackButton.vue";

export default defineComponent({
  name: "policy-information-credit-note",
  components: { GoBackButton },
  props: ["publicId"],
  data() {
    return {
      receipt: {}
    };
  },
  setup() {
    const store = useStore();

    const user = computed(() => {
      return store.getters.currentUser;
    });

    onMounted(() => {
      setCurrentPageBreadcrumbs("Details", ["Client", "Credit Notes"]);
    });

    return { user };
  },
  created() {
    this.getReceiptInformation();

    //Set page title
    document.title = "Credit Note Details | " + process.env.VUE_APP_TITLE;
  },
  methods: {
    getReceiptInformation() {
      const router = useRouter();

      //Fetch individual credit note information
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(`/underwriter/credit-note/${this.publicId}`)
          .then(({ data }) => {
            //Assign data to variables
            this.receipt = data.data;
          })
          .catch(function (error) {
            variables.toastAlert(error.response.data.error, "error");
            router.go(-1);
          });
      }
    },
    addCommaToNumberString(amount) {
      return variables.addCommaToNumberString(amount);
    },
    capitalize(value) {
      return variables.capitalize(value);
    },
    printPage(el) {
      variables.printPage(el);
    }
  }
});
